<template>
  <div class="emb-termCondition-wrap">
    <div class="d-flex justify-center text-h4 ma-6 pt-3 font-weight-bold">
      {{ $t("message.termAndConditionPage.title") }}
    </div>
    <div class="d-flex justify-center text-h6">
      {{ $t("message.termAndConditionPage.subTitle") }}
    </div>
    <div class="emb-tnc section-gap">
      <div class="container">
        <div class="tnc-content">
          <div
            class="emb-card mb-12 pa-6"
            v-for="(data, i) in tncLocale"
            :key="i"
          >
            <div class="text-h5 mb-4">{{ data.name }}</div>
            <div v-html="data.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tncData from "Assets/data/tncData";
export default {
  data() {
    return {
      tncLocale: [],
      tncData,
    };
  },
  mounted() {
    this.getTnc();
  },
  watch: {
    "$i18n.locale": function () {
      this.getTnc();
    },
  },
  methods: {
    getTnc() {
      this.tncLocale = this.tncData[this.$i18n.locale];
    },
  },
};
</script>

